// IMPORTS
@import (reference) "../settings.less";

// MAIN PAGE BOTTOM WRAPPER
.main-page-bottom-wrapper {
    display: flex;
    gap: 32px;

    > * {
        flex-grow: 1;
    }
}

.main-page-contact {
    display: flex;
    flex-direction: column;
    background-color: #E0E8E2;
    min-width: 322px;
    padding: 40px 24px;
    border-radius: var(--borderRadius);
    gap: 24px;
    justify-content: center;

    p {
        font-weight: bold;
        font-size: 14px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
            display: inline-block;
            color: @font-color;
            .line-height();

            &:before {
                font-family: icomoon;
                float: left;
                font-size: 24px;
                margin-right: 8px;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .email:before {
            content: '\e914';
        }

        .phone:before {
            content: '\e916';
        }
    }

    > a {
        color: white;
        background-color: @main-color;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 8px;
        border-radius: var(--borderRadius);

        &:hover {
            background-color: @main-dark;
        }
    }
}

.main-greeting {
    background-color: @light-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    padding: 40px;
    border-radius: var(--borderRadius);

    .headline {
        margin-bottom: 0;
    }
}